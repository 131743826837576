import { CookiesHandler } from "./cookies-handler";
import { Utilities } from "./utilities";

export class Popups {
    default_options = {
        name: "Anonymous Popup",
        id: "",
        cookie_name: "anonimousPopup",
        cookie_value: "closed",
        cookie_expire: 365,
        block_on_click: false,
        block_on_show: false,
        position: "bottom",
    };

    popupOptions = {};

    CH;

    constructor() {
        this.CH = new CookiesHandler();
        this.initPopups();
    }

    initPopups(options) {
        console.log("initPopups");

        const popupElements = $("[data-popup]");

        if (popupElements.length > 0) {
            popupElements.each((index, element) => {
                this.popupOptions.name =
                    $(element).data("name") ?? this.default_options.name;
                this.popupOptions.id =
                    $(element).attr("id") ?? this.default_options.id;
                this.popupOptions.cookie_name =
                    $(element).data("cookiename") ??
                    this.default_options.cookie_name;
                this.popupOptions.cookie_value =
                    $(element).data("cookievalue") ??
                    this.default_options.cookie_value;
                this.popupOptions.cookie_expire =
                    $(element).data("cookieexpire") ??
                    this.default_options.cookie_expire;
                this.popupOptions.position =
                    $(element).data("position") ??
                    this.default_options.position;
                this.popupOptions.block_on_click =
                    $(element).data("blockonclick") ??
                    this.default_options.block_on_click;
                this.popupOptions.block_on_show =
                    $(element).data("blockonshow") ??
                    this.default_options.block_on_show;

                console.log(this.popupOptions);

                this.handleOpening();
            });
        }
    }

    handleOpening() {
        if (
            this.popupOptions.block_on_click == true ||
            this.popupOptions.block_on_show == true
        ) {
            if (
                this.CH.getCookie(this.popupOptions.cookie_name) !=
                this.popupOptions.cookie_value
            ) {
                console.log("handleOpening cookie is different than value");
                this.openPopUp();
            }
        } else {
            this.openPopUp();
        }
    }

    openPopUp() {
        $("#" + this.popupOptions.id).addClass(
            "position-" + this.popupOptions.position
        );
        $("#" + this.popupOptions.id).addClass("opened");

        dataLayer.push = {
            event: "popup open",
            popupData: {
                id: this.popupOptions.id,
                name: this.popupOptions.name,
            },
        };

        if (this.popupOptions.block_on_show === true) {
            this.CH.writeCookie({
                name: this.popupOptions.cookie_name,
                value: this.popupOptions.cookie_value,
                expires: this.popupOptions.cookie_expire,
            });
        }

        $("#" + this.popupOptions.id)
            .find(".popup--close")
            .on("click", (e) => {
                this.closePopUp();
            });
    }

    closePopUp() {
        $("#" + this.popupOptions.id).removeClass("opened");

        if (this.popupOptions.block_on_click === true) {
            this.CH.writeCookie({
                name: this.popupOptions.cookie_name,
                value: this.popupOptions.cookie_value,
                expires: this.popupOptions.cookie_expire,
            });
        }

        dataLayer.push = {
            event: "popup closed",
            popupData: {
                id: this.popupOptions.id,
                name: this.popupOptions.name,
            },
        };
    }
}
