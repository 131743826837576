import { Utilities } from "./utilities";
import $ from 'jquery';

class BodyResizes {
    
    constructor() {
        this.setBodyTopPadding();
    }

    setBodyTopPadding() {
        const mainHeaderHeight = $('#mainHeader').outerHeight();
        $('body').css("padding-top",mainHeaderHeight);
    }

}

export {BodyResizes};