import Handlebars from "handlebars";
import $ from "jquery";

class Utilities {
    static slugifyString(str) {
        return str
            .toLowerCase()
            .trim()
            .replace(/[^\w\s-]/g, "")
            .replace(/[\s_-]+/g, "-")
            .replace(/^-+|-+$/g, "");
    }

    static getAverageValueFromArray(array) {
        const sum = array.reduce((acc, current) => acc + current, 0);
        return sum / array.length;
    }

    static removeItemFromArray(value_to_remove, array) {
        const index = array.indexOf(value_to_remove);
        if (index != -1) {
            const x = array.splice(index, 1);
        }
    }

    static valueExistsInArray(value, array) {
        return array.includes(value);
    }

    static async renderHandlebarsTemplate(data, templateSource) {
        try {
            // Compila il template con i dati
            const template = Handlebars.compile(templateSource);
            const compiledHTML = template(data);

            // Restituisci il template compilato
            return compiledHTML;
        } catch (error) {
            console.error(
                "Errore durante la compilazione del template:",
                error
            );
            return ""; // In caso di errore, restituisci una stringa vuota
        }
    }

    static isObjectEmpty(obj) {
        return Object.keys(obj).length === 0;
        //return ( Object.keys(obj).length === 0 ) ? true : false ;
    }

    static prepareFormDataForAjax(search_parameters) {
        const formData = new FormData();
        const default_search_params = {
            area: [],
            type: [],
            dep: [],
            serv: [],
            access_token: "",
            store_id: "",
        };

        const new_search_params = $.extend(
            true,
            {},
            default_search_params,
            search_parameters
        );
        console.log(new_search_params);

        for (const [index, element] of Object.entries(new_search_params)) {
            if (index === "access_token" || index === "store_id") {
                formData.append(index, element);
            } else {
                if (Array.isArray(element)) {
                    const value =
                        element.length > 0 ? JSON.stringify(element) : "[]";
                    formData.append(index, value);
                } else {
                    formData.append(index, element);
                }
            }
        }

        console.log(JSON.stringify(formData));
        return formData;
    }

    static loadSpinner(target_element) {
        const markup =
            '<div class="spinner-wrapper"><div class="spinner"><div class="spinner-inner"></div></div></div>';
        $(target_element).append(markup);
    }

    static startSpinner(target_element) {
        $(target_element).find(".spinner-wrapper").fadeIn();
    }

    static hideSpinner(target_element) {
        $(target_element).find(".spinner-wrapper").hide();
    }

    static isMobile() {
        const mobileQuery = window.matchMedia("(max-width: 1024px)");
        return mobileQuery.matches;
    }

    static isPhone() {
        const mobileQuery = window.matchMedia("(max-width: 768px)");
        return mobileQuery.matches;
    }

    static elementExits(DOM_element) {
        return document.querySelector(DOM_element) !== null ? true : false;
    }

    static isPage(body_ID_to_search) {
        let body_ID = $("body").attr("id");
        if (body_ID !== undefined && body_ID !== "") {
            body_ID = "#" + body_ID;
            return body_ID === body_ID_to_search ? true : false;
        } else {
            return false;
        }
    }

    static detectOS() {
        function getMobileOperatingSystem() {
            var userAgent =
                navigator.userAgent || navigator.vendor || window.opera;

            // Windows Phone must come first because its UA also contains "Android"
            if (/windows phone/i.test(userAgent)) {
                return "Windows Phone";
            }

            if (/android/i.test(userAgent)) {
                return "Android";
            }

            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }

            return "unknown";
        }
    }

    static empty(mixedVar) {
        let undef;
        let key;
        let i;
        let len;
        const emptyValues = [undef, null, false, 0, "", "0"];
        for (i = 0, len = emptyValues.length; i < len; i++) {
            if (mixedVar === emptyValues[i]) {
                return true;
            }
        }
        if (typeof mixedVar === "object") {
            for (key in mixedVar) {
                if (mixedVar.hasOwnProperty(key)) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }
}

export { Utilities };
