import $ from "jquery";

export function panelCollapse() {

    $('.panel-toggle').on('click', (e) => {
        
        let toggleTarget = $(e.currentTarget).data('collapse');
        
        if( !$(e.currentTarget).hasClass('active') ) {
            $(e.currentTarget).addClass('active');
            $(toggleTarget).addClass('active');
            $(toggleTarget).slideDown();
        }else{
            $(e.currentTarget).removeClass('active');
            $(toggleTarget).removeClass('active');
            $(toggleTarget).slideUp();
        }

    });

}