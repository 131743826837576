import jQuery from 'jquery';
// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
;( function( $, window, document, undefined ) {

	"use strict";

		// undefined is used here as the undefined global variable in ECMAScript 3 is
		// mutable (ie. it can be changed by someone else). undefined isn't really being
		// passed in so we can ensure the value of it is truly undefined. In ES5, undefined
		// can no longer be modified.

		// window and document are passed through as local variables rather than global
		// as this (slightly) quickens the resolution process and can be more efficiently
		// minified (especially when both are regularly referenced in your plugin).

		// Create the defaults once
		var pluginName = "cookieConsent",
			defaults = {
                templatesDir: "/templates/coop/libs/cookie-consent/markup/",
                banner_element: "",
                listeners: {
                    marketing: '#acceptMarketingCookies',
                    stats: '#acceptStatsCookies',
                    all: '#acceptAllCookies',
                    deny_all: '#denyAllCookies',
                    banner: '#cookieConsentBanner',
                    popup: '#cookieModal'
                },
                cookies: {
                    names: {
                        "consent_done": "cookieConsent",
                        "all": "cookieConsent_all",
                        "stats": "cookieConsent_stats",
                        "marketing": "cookieConsent_marketing"
                    },
                    values: {
                        "consent_done": "done",
                        "all": "true",
                        "stats": "true",
                        "marketing": "true"
                    }
                },
                force: {
                    marketing: false,
                    stats: false
                },
                track: true,
                debug: true
			};

		// The actual plugin constructor
		function Plugin ( element, options ) {
			this.element = element;

			// jQuery has an extend method which merges the contents of two or
			// more objects, storing the result in the first object. The first object
			// is generally empty as we don't want to alter the default options for
			// future instances of the plugin
			this.settings = $.extend( {}, defaults, options );
			this._defaults = defaults;
			this._name = pluginName;
			this.init();
		}

		// Avoid Plugin.prototype conflicts
		$.extend( Plugin.prototype, {
			init: function() {

                if( this.debug === true ) {
                    console.log('######################################');
                    console.log('COOP COOKIE CONSTENT');
                    console.log('######################################');
                    console.log('CCC -> start()');
                }

				// Place initialization logic here
				// You already have access to the DOM element and
				// the options via the instance, e.g. this.element
				// and this.settings
				// you can add more functions like the one below and
				// call them like the example below

                var plugin = this;

                // Set the DOM listeners
                this.listeners = {
                    marketing: $(this.element).find(this.settings.listeners.marketing),
                    stats: $(this.element).find(this.settings.listeners.stats),
                    all: $(this.element).find(this.settings.listeners.all),
                    banner: $(this.element).find(this.settings.listeners.banner)
                };

                // Get the cookies values
                this.cookie_consent_action = this.getSingleCookieStatus(plugin,this.settings.cookies.names.consent_done);
                this.cookie_consent_marketing = this.getSingleCookieStatus(plugin,this.settings.cookies.names.marketing);
                this.cookie_consent_stats = this.getSingleCookieStatus(plugin,this.settings.cookies.names.stats);
                this.cookie_consent_all = this.getSingleCookieStatus(plugin,this.settings.cookies.names.all);

                this.show_banner = false;
                this.is_policy_page = false;

                if(this.settings.debug == true) {
                    console.log('--------------------------------------');
                    console.log('CCC Listeners');
                    console.log('--------------------------------------');
                    console.log('MARKETING: ' + this.listeners.marketing.attr('id'));
                    console.log('STATS: ' + this.listeners.stats.attr('id'));
                    console.log('ALL: ' + this.listeners.all.attr('id'));
                }


                if(this.settings.debug == true) {
                    console.log('--------------------------------------');
                    console.log('CCC Choises');
                    console.log('--------------------------------------');
                    console.log('ACTION: ' + this.cookie_consent_action);
                    console.log('MARKETING: ' + this.cookie_consent_marketing);
                    console.log('STATS: ' + this.cookie_consent_stats);
                    console.log('ALL: ' + this.cookie_consent_all);
                }

                if(this.settings.force.marketing == true) {
                    this.forceCookieToFalse(plugin,"marketing");
                }

                if(this.settings.force.stats == true) {
                    this.forceCookieToFalse(plugin,"stats");
                }

                this.updateChoisesStatus(plugin);
                this.show_banner = this.isBannerNeeded(plugin);

                if(this.listeners.marketing.length > 0 || this.listeners.stats.length > 0) {
                    this.is_policy_page = true;
                    this.mountPolicyMarkup(plugin);
                }

                if(this.settings.debug == true) {
                    console.log('SHOW BANNER: ' + this.show_banner);
                    console.log('IS POLICY PAGE: ' + this.is_policy_page);
                }

                if(this.is_policy_page == false && this.show_banner == true) {
                    this.showCookieBanner(plugin);
                }

                if(this.is_policy_page == false) {
                    this.mountPopupMarkup(plugin);
                }else{
                    $('a[href="#openCookieModal"]').hide();
                }

			},

            forceCookieToFalse: function(plugin,type) {
                plugin.writeCookie({
                    name: plugin.settings.cookies.names[type],
                    value: false,
                    expires: 365
                });
            },

            updateChoisesStatus: function(plugin) {
                if (plugin.cookie_consent_marketing == undefined || plugin.cookie_consent_stats == undefined) {
                    plugin.deleteCookie(plugin.settings.cookies.names.consent_done);
                    plugin.deleteCookie(plugin.settings.cookies.names.all);
                    return true;
                }
                if(plugin.cookie_consent_marketing != undefined && plugin.cookie_consent_stats != undefined && plugin.cookie_consent_action == undefined) {
                     plugin.writeCookie({
                        name: plugin.settings.cookies.names.consent_done,
                        value: plugin.settings.cookies.values.consent_done,
                        expires: 365
                    });
                }
            },

            isBannerNeeded: function(plugin) {
                if (plugin.cookie_consent_marketing == undefined && plugin.cookie_consent_stats == undefined) {
                    return true;
                }else{
                    return false;
                }
            },

            getSingleCookieStatus: function(plugin,cookie) {

                if(plugin.cookieExits(cookie) == false) {
                    return undefined;
                }else{
                    return plugin.readCookie(cookie);
                }

            },


            mountPopupMarkup: function(plugin) {
                $.ajax({
                    url: plugin.settings.templatesDir + "popup.html",
                    dataType: "html",
                    success: function(popup) {
                        $(plugin.element).append(popup);
                        plugin.handleCookiePopUp(plugin);
                        $('a[href="#openCookieModal"]').on('click', function(e) {
                            e.preventDefault();
                            $(plugin.settings.listeners.popup).modal('show');
                        });
                    },
                    error: function() {
                        console.log('AJAX ERROR');
                    }
                });
            },

            /* ************************************* */
            /* POLICY PAGE */
            /* ************************************* */


            /*  Mount Policy Page elements
            ------------------------------------------------------------------------
                This function get the buttons markup and mount them on the page,
                inside the listeners, then active them using the
                ** startPolicyListener() ** function
            ------------------------------------------------------------------------
            */

            mountPolicyMarkup: function(plugin) {

                $.ajax({
                    url: plugin.settings.templatesDir + "button_marketing.html",
                    dataType: "html",
                    success: function(template) {
                        if(plugin.settings.debug == true) {
                            console.log('AJAX URL: ' + plugin.settings.templatesDir + "button_marketing.html");
                            console.log('Template Marketing: ' + template);
                        }
                        plugin.listeners.marketing.html(template);
                        plugin.startPolicyListener(plugin,"marketing");
                    },
                    error: function() {
                        console.log('AJAX ERROR');
                    }
                }); // end ajax

                $.ajax({
                    url: plugin.settings.templatesDir + "button_stats.html",
                    dataType: "html",
                    success: function(template) {
                        plugin.listeners.stats.html(template);
                        plugin.startPolicyListener(plugin,"stats");
                    },
                    error: function() {
                        console.log('AJAX ERROR');
                    }
                }); // end ajax

                $.ajax({
                    url: plugin.settings.templatesDir + "button_all.html",
                    dataType: "html",
                    success: function(template) {
                        plugin.listeners.all.html(template);
                        plugin.startPolicyListener(plugin,"all");
                    },
                    error: function() {
                        console.log('AJAX ERROR');
                    }
                }); // end ajax


            },


            /*  Start Policy Listeners
            ------------------------------------------------------------------------
                This function is a callback of the
                ** mountPolicyMarkup() ** function
            ------------------------------------------------------------------------
            */
            startPolicyListener: function(plugin, type, deferred = false) {

                if(plugin.settings.debug == true) {
                    console.log('START startPolicyListener(plugin,'+type+')');
                }

                if ( deferred == true ) {

                    var listeners = {
                        marketing: $(plugin.settings.listeners.marketing),
                        stats: $(plugin.settings.listeners.stats),
                        all: $(plugin.settings.listeners.all),
                        banner: $(plugin.settings.listeners.banner)
                    };

                }else{
                    var listeners = plugin.listeners;
                }

                switch(type) {

                    case "marketing":


                        if (plugin.cookie_consent_marketing == undefined) {
                            listeners.marketing.find('.button_marketing').removeClass('accepted');
                            listeners.marketing.find('.button_marketing').removeClass('not_acceptedremoveClass');
                        }
                        else if (plugin.cookie_consent_marketing == plugin.settings.cookies.values.marketing) {
                            listeners.marketing.find('.button_marketing').addClass('accepted');
                        }
                        else{
                            listeners.marketing.find('.button_marketing').addClass('not_accepted');
                        }

                        listeners.marketing.find('.button_yes').on('click', function() {

                            var thisButton = $(this);

                            plugin.writeCookie({
                                name: plugin.settings.cookies.names.marketing,
                                value: plugin.settings.cookies.values.marketing,
                                expires: 365,
                                callback: function() {
                                    thisButton.closest('.button_marketing').removeClass('not_accepted');
                                    thisButton.closest('.button_marketing').addClass('accepted');
                                    if (plugin.cookieExits(plugin.settings.cookies.names.stats) == true) {
                                        plugin.setCookieActionDone(plugin,365);
                                    }
                                    plugin.trackTheClick(plugin,"marketing");
                                    plugin.cookiePopUp_handle_save_buttons(plugin,true);
                                }
                            });

                        });

                        listeners.marketing.find('.button_no').on('click', function() {

                            var thisButton = $(this);

                            plugin.writeCookie({
                                name: plugin.settings.cookies.names.marketing,
                                value: false,
                                expires: 365,
                                callback: function() {
                                    thisButton.closest('.button_marketing').removeClass('accepted');
                                    thisButton.closest('.button_marketing').addClass('not_accepted');
                                    if (plugin.cookieExits(plugin.settings.cookies.names.stats) == true) {
                                        plugin.setCookieActionDone(plugin,7);
                                    }
                                    plugin.cookiePopUp_handle_save_buttons(plugin,true);
                                }
                            });

                        });

                        break;

                    case "stats":

                        if (plugin.cookie_consent_stats == undefined) {
                            listeners.stats.find('.button_marketing').removeClass('accepted');
                            listeners.stats.find('.button_marketing').removeClass('not_acceptedremoveClass');
                        }
                        else if (plugin.cookie_consent_stats == plugin.settings.cookies.values.stats) {
                            listeners.stats.find('.button_stats').addClass('accepted');
                        }
                        else{
                            listeners.stats.find('.button_stats').addClass('not_accepted');
                        }

                        listeners.stats.find('.button_yes').on('click', function() {

                            var thisButton = $(this),
                                cookieTime;

                            //if() @todo: cambio durata cookie

                            plugin.writeCookie({
                                name: plugin.settings.cookies.names.stats,
                                value: plugin.settings.cookies.values.stats,
                                expires: 365,
                                callback: function() {
                                    thisButton.closest('.button_stats').removeClass('not_accepted');
                                    thisButton.closest('.button_stats').addClass('accepted');
                                    if (plugin.cookieExits(plugin.settings.cookies.names.marketing) == true) {
                                        plugin.setCookieActionDone(plugin,365);
                                    }
                                    plugin.trackTheClick(plugin,"stats");
                                    plugin.cookiePopUp_handle_save_buttons(plugin,true);
                                }
                            });

                        });

                        listeners.stats.find('.button_no').on('click', function() {

                            var thisButton = $(this);

                            plugin.writeCookie({
                                name: plugin.settings.cookies.names.stats,
                                value: false,
                                expires: 365,
                                callback: function() {
                                    thisButton.closest('.button_stats').removeClass('accepted');
                                    thisButton.closest('.button_stats').addClass('not_accepted');
                                    if (plugin.cookieExits(plugin.settings.cookies.names.marketing) == true) {
                                        plugin.setCookieActionDone(plugin,7);
                                    }
                                    plugin.cookiePopUp_handle_save_buttons(plugin,true);
                                }
                            });

                        });

                        break;

                    case "all":

                        if (plugin.cookie_consent_stats == undefined || plugin.cookie_consent_stats == undefined) {
                            listeners.all.find('.button_all').show();
                        }

                        listeners.all.find('.button_yes').on('click', function() {

                            var thisButton = $(this);

                            thisButton.closest('.button_all').addClass('accepted');

                            plugin.writeCookie({
                                name: plugin.settings.cookies.names.all,
                                value: plugin.settings.cookies.values.all,
                                expires: 365,
                                callback: function() {
                                    listeners.stats.find('.button_stats').removeClass('not_accepted');
                                    listeners.stats.find('.button_stats').addClass('accepted');
                                    listeners.marketing.find('.button_marketing').removeClass('not_accepted');
                                    listeners.marketing.find('.button_marketing').addClass('accepted');

                                     plugin.writeCookie({
                                        name: plugin.settings.cookies.names.marketing,
                                        value: plugin.settings.cookies.values.marketing,
                                        expires: 365,
                                        callback: function() {

                                            plugin.trackTheClick(plugin,"marketing");

                                            plugin.writeCookie({
                                                name: plugin.settings.cookies.names.stats,
                                                value: plugin.settings.cookies.values.stats,
                                                expires: 365,
                                                callback: function() {
                                                    plugin.setCookieActionDone(plugin,365);
                                                    plugin.trackTheClick(plugin,"stats");
                                                }
                                            });

                                        }
                                    });

                                }
                            });

                        });

                        break;
                } // end switch

            },

            setCookieActionDone: function(plugin,cookietime) {
                plugin.writeCookie({
                    name: plugin.settings.cookies.names.consent_done,
                    value: plugin.settings.cookies.values.consent_done,
                    expires: cookietime,
                    callback: function() {
                        if(plugin.settings.debug == true) console.log('ccc -> setCookieActionDone()');
                    }
                });
            },

            showCookieBanner: function(plugin) {
                $.ajax({
                    url: plugin.settings.templatesDir + "banner.html",
                    dataType: "html",
                    success: function(banner) {
                        $(plugin.element).append(banner);
                        plugin.bannerListener(plugin);
                    },
                    error: function() {
                        console.log('AJAX ERROR');
                    }
                }); // end ajax
            },

            handleCookiePopUp: function(plugin) {

                var popup = $(plugin.settings.listeners.popup);

                if(popup.length > 0) console.log('POPUP Exists');

                plugin.startPolicyListener(plugin, "marketing", true);
                plugin.startPolicyListener(plugin, "stats", true);

                if ( plugin.cookie_consent_marketing != undefined || plugin.cookie_consent_stats != undefined ) {
                    plugin.cookiePopUp_handle_save_buttons(plugin,true);
                }else{
                    plugin.cookiePopUp_handle_save_buttons(plugin,false);
                }

                popup.find('.button-accept').on('click', function(e) {
                    e.preventDefault();
                    console.log('popup accept all');
                    plugin.acceptAllCookies(plugin);
                    plugin.hideCookieBanner(plugin);
                });


                popup.find('.button-deny-all').on('click', function(e) {
                    e.preventDefault();
                    plugin.denyAllCookies(plugin);
                    plugin.hideCookieBanner(plugin);
                });
            },

            cookiePopUp_handle_save_buttons: function(plugin, show_save_buttons = true) {
                if( show_save_buttons === true ) {
                    $(plugin.settings.listeners.popup).find('.modal-accept-deny-buttons').hide();
                    $(plugin.settings.listeners.popup).find('.modal-save-buttons').show();
                }
                if( show_save_buttons === false ) {
                    $(plugin.settings.listeners.popup).find('.modal-save-buttons').hide();
                    $(plugin.settings.listeners.popup).find('.modal-accept-deny-buttons').show();
                }

                $('.modal-save-buttons a.close-banner').on('click', function(e) {
                    e.preventDefault();
                    plugin.hideCookieBanner(plugin);
                });
            },

            bannerListener: function(plugin) {
                $(plugin.settings.listeners.banner).find('.button-accept').on('click', function(e) {
                    e.preventDefault();
                    plugin.acceptAllCookies(plugin);
                    plugin.hideCookieBanner(plugin);
                });


                $(plugin.settings.listeners.banner).find('.button-deny-all').on('click', function(e) {
                    e.preventDefault();
                    plugin.denyAllCookies(plugin);
                    plugin.hideCookieBanner(plugin);
                });
            },

            acceptAllCookies: function(plugin) {

                var cookie_value = true,
                    cookie_expire_time = 365;

                plugin.writeCookie({
                    name: plugin.settings.cookies.names.marketing,
                    value: plugin.settings.cookies.values.marketing,
                    expires: cookie_expire_time,
                    callback: function() {
                        plugin.trackTheClick(plugin,"marketing");
                    }
                });

                plugin.writeCookie({
                    name: plugin.settings.cookies.names.stats,
                    value: plugin.settings.cookies.values.stats,
                    expires: cookie_expire_time,
                    callback: function() {
                        plugin.trackTheClick(plugin,"stats");
                    }
                });

                plugin.writeCookie({
                    name: plugin.settings.cookies.names.all,
                    value: cookie_value,
                    expires: cookie_expire_time,
                    callback: function() {}
                });

                plugin.setCookieActionDone(plugin,cookie_expire_time);

            },

            denyAllCookies: function(plugin) {

                var cookie_value = false,
                    cookie_expire_time = 365;

                plugin.writeCookie({
                    name: plugin.settings.cookies.names.marketing,
                    value: cookie_value,
                    expires: cookie_expire_time,
                    callback: function() {}
                });

                plugin.writeCookie({
                    name: plugin.settings.cookies.names.stats,
                    value: cookie_value,
                    expires: cookie_expire_time,
                    callback: function() {}
                });

                plugin.writeCookie({
                    name: plugin.settings.cookies.names.all,
                    value: cookie_value,
                    expires: cookie_expire_time,
                    callback: function() {}
                });

                plugin.setCookieActionDone(plugin,cookie_expire_time);
            },

            hideCookieBanner: function(plugin) {
                $(plugin.settings.listeners.banner).slideUp();
            },



            /* ************************************* */
            /* COOKIE ACTIONS */
            /* ************************************* */

            writeCookie: function(opt) {

                /*{
                    name:
                    value:
                    expires:
                    callback:
                }*/

                var d = new Date();
                d.setTime(d.getTime() + (opt.expires*24*60*60*1000));
                var expires = "expires="+d.toUTCString();
                document.cookie = opt.name + "=" + opt.value + "; " + expires + "; path=/";
                if (opt.callback !== undefined && opt.callback !== "") {

                    var newCookie = this.readCookie(opt.name);
                    if (newCookie !== undefined && newCookie !== "") {
                        opt.callback(true);
                    }else{
                        opt.callback(false);
                    }
                }
            },

            deleteCookie: function(name,callback) {
                document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
                if (callback !== undefined && callback !== "") callback();
            },

            readCookie: function(name) {
                name = name + "=";
                var ca = document.cookie.split(';');
                for(var i=0; i<ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0)==' ') c = c.substring(1);
                    if (c.indexOf(name) == 0) return c.substring(name.length,c.length);
                }
                return "";
            },

            cookieExits: function(name) {
                if (document.cookie.indexOf(name) != -1)  {
                    return true;
                } else {
                    return false;
                }
            },

            trackTheClick: function(plugin,type) {

                var event_label;

                switch(type) {
                    case "marketing":
                        event_label = 'clickConsentMKTG';
                        break;
                    case "stats":
                        event_label = 'clickConsentSTATS';
                        break;
                }

                if (plugin.settings.track == true) {
                    dataLayer.push({
                        'event': event_label
                    });
                }

            }


		} );

		// A really lightweight plugin wrapper around the constructor,
		// preventing against multiple instantiations
		$.fn[ pluginName ] = function( options ) {
			return this.each( function() {
				if ( !$.data( this, "plugin_" + pluginName ) ) {
					$.data( this, "plugin_" +
						pluginName, new Plugin( this, options ) );
				}
			} );
		};

} )( jQuery, window, document );
