import $ from "jquery";
import { Utilities } from "./utilities";
import { StoreLocatorGetData } from "./store-locator--get-data";

class StoreLocatorMap {

    constructor() {
        this.mapOnWindowLoad();
        this.mapOnWindowResize();
    }

    mapOnWindowLoad() {
        $(window).on("load", () => {
            this.resizeMap();
        });
    }

    mapOnWindowResize() {
        $(window).on("resize", () => {
            this.resizeMap();
        });
    }

    resizeMap() {

        var stores_list_element = $("#storesList"),
            stores_list_offset = stores_list_element.offset(),
            stores_list_width = stores_list_element.outerWidth(),
            map_left_value = stores_list_offset.left + stores_list_width;

        if ( !Utilities.isMobile() ) {
            console.log(Utilities.isMobile());
            $("#storeLocatorMap").css("left", map_left_value);
        }

    }

    getOpenNowLabel(store_is_open_now) {

        let store_open_label;

        if( store_is_open_now === true ) {
            store_open_label = '<span class="open-label"><i class="fa-duotone fa-lock-open"></i> Aperto</span>';
        }else{
            store_open_label = '<span class="closed-label"><i class="fa-duotone fa-lock"></i> Chiuso</span>';
        }

        return store_open_label;

    }

    async mountMapComponents(parameters={}) {

        //console.log( "mountMapComponents params: " + JSON.stringify(parameters))
        const locator = new StoreLocatorGetData();

        try {
            const stores = await locator.getStores(parameters);

            const mapData = {
                center: {
                    lat: 0,
                    lng: 0
                },
                stores: []
            };
            const coordinates = {
                lats: [],
                lngs: []
            };
            const storesArray = [];

            $.each(stores, (index, element) => {

                let store_open_label = this.getOpenNowLabel(element.postmeta.sl_store__open_now);

                let thisStore = {
                    store_name: element.store_name,
                    store_slug: element.store_slug,
                    store_type: this.getStoreType(element.type_coop_ci),
                    /* store_type: {
                        name: Object.values(element.type_coop_ci)[0],
                        slug: Utilities.slugifyString(Object.values(element.type_coop_ci)[0])
                    }, */
                    store_address: element.postmeta.sl_store__geolocation_address,
                    store_zip: element.postmeta.sl_store__geolocation_zip,
                    store_city: element.postmeta.sl_store__geolocation_city,
                    store_state: element.postmeta.sl_store__geolocation_state,
                    store_country: element.postmeta.sl_store__geolocation_country,
                    store_coords: element.postmeta.sl_store__geolocation_map,
                    store_latitude: Number(element.postmeta.sl_store__geolocation_latitude),
                    store_longitude: Number(element.postmeta.sl_store__geolocation_longitude),
                    store_open_now: element.postmeta.sl_store__open_now,
                    //store_open_label: DOMPurify.sanitize(store_open_label),
                    store_open_label: store_open_label,
                    store_url: locator.getStoreURL(element)
                };

                coordinates.lats.push( Number(element.postmeta.sl_store__geolocation_latitude) );
                coordinates.lngs.push( Number(element.postmeta.sl_store__geolocation_longitude) );

                storesArray.push(thisStore);


                mapData.stores = storesArray;

            });


            console.log(mapData);
            if( !Utilities.isObjectEmpty(mapData.stores) ) {

                mapData.center.lat = Utilities.getAverageValueFromArray(coordinates.lats);
                mapData.center.lng = Utilities.getAverageValueFromArray(coordinates.lngs);

                this.resetNoStoreFoundMessage();
                this.mountGoogleMap(mapData);
                this.mountStoresList(mapData);

            }else{

                this.mountNoStoreFoundMessage();

            }



        } catch (error) {
            console.log('Error creating store data for store locator: ' + error)
        }

    }

    getStoreType( store_type_object ) {

        let store_type = {
            name: "",
            slug: ""
        }
        let counter = 0;

        $.each( store_type_object, (i,el) => {
            store_type.name = el.name;
            store_type.slug = el.slug;
            return false;
        });

        return store_type;
    }

    mountNoStoreFoundMessage() {
        console.log('mount error message')
        const error_message__not_stores_found = '<div class="no-stores-found"><i class="fa-solid fa-circle-exclamation"></i> Nessun negozio trovato per i criteri selezionati.<br> Modifica i parametri di ricerca.</div>';
        document.querySelector('.store-locator--form-error-message').innerHTML = error_message__not_stores_found;
        $('.store-locator--form-error-message .no-stores-found').show();
    }

    resetNoStoreFoundMessage() {
        document.querySelector('.store-locator--form-error-message').innerHTML = "";
        $('.store-locator--form-error-message .no-stores-found').hide();
    }

    async mountStoresList(mapData) {

        const single_store_template =  `
            <a href="{{ store_url }}" class="stores-nav--card brand-{{ store_type.slug }}">
                <h3 class="stores-nav--card-title">
                    {{ store_name }}
                </h3>
                <div class="stores-nav--card-address">
                    <span class="city">{{ store_city }} ({{ store_state }})</span>
                    <span class="street">{{ store_address }}</span>
                </div>
                <div class="stores-nav--card-logo">
                    <span class="visually-hidden">Tipologia negozio: {{ store_type.name }}</span>
                </div>
                <div class="stores-nav--card-open">{{{ store_open_label }}}</div>
            </a>
        `;

        const compiledTemplates = await Promise.all(
            mapData.stores.map(data => Utilities.renderHandlebarsTemplate(data, single_store_template))
        );

        const renderedTemplate = compiledTemplates.join('');

        const outputElement = document.getElementById('storesNav');

        outputElement.innerHTML = renderedTemplate;

    }

    /**
     * ---------------------------------------
     * Mount Google Map
     * ---------------------------------------
     */
    mountGoogleMap(mapData) {

        Utilities.loadSpinner( "#storeLocatorMap" );

        let map;

        async function initMap() {
            const { Map } = await google.maps.importLibrary("maps");

            map = new Map(document.getElementById("storeLocatorMapBox"), {
                center: { lat: mapData.center.lat, lng: mapData.center.lng },
                zoom: 8,
            });

            new google.maps.Marker({
                position: {lat: mapData.center.lat, lng: mapData.center.lng},
                map,
                title: "Center",
            });

            const svgMarker = {
                path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
                fillColor: "blue",
                fillOpacity: 0.6,
                strokeWeight: 0,
                rotation: 0,
                scale: 2,
                anchor: new google.maps.Point(0, 20),
            };

            const mapPinImg = {
                url: (process.env.GOOGLE_MAP_PIN) ? process.env.GOOGLE_MAP_PIN : GOOGLE_MAP_PIN,
                scaledSize: new google.maps.Size(30, 30),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(0, 0),
            }

            $.each(mapData.stores, (index, element) => {

                let contentString = '<div class="info-window brand-' + element.store_type.slug + '">';
                contentString += '      <div class="info-window--inner">';
                contentString += '          <div class="info-window--text">';
                contentString += '              <div class="info-window--text--storename">' + element.store_name + '</div>';
                contentString += '              <div class="info-window--text--storeaddress">' + element.store_address + '<br>' + element.store_city + '(' + element.store_state + ')</div>';
                contentString += '          </div>';
                contentString += '          <div class="info-window--logo">';
                contentString += '          </div>';
                contentString += '          <div class="info-window--footer">';
                contentString += '              <div class="info-window--open-now">';
                contentString +=                   element.store_open_label;
                contentString += '              </div>';
                contentString += '              <div class="info-window--storelink">';
                contentString += '              </div>';
                contentString += '          </div>';
                contentString += '      </div>';
                contentString += '</div>';

                const infowindow = new google.maps.InfoWindow({
                    content: contentString,
                    ariaLabel: element.store_name,
                });

                let markerData = {
                    position: { lat: element.store_latitude, lng: element.store_longitude  },
                    icon: mapPinImg,
                    map: map,
                    title: element.store_name
                };

                let marker = new google.maps.Marker(markerData);

                marker.addListener("click", () => {
                    infowindow.open({
                      anchor: marker,
                      map,
                    });
                });
            });



        }

        initMap();
        Utilities.hideSpinner( "#storeLocatorMap" );

    }
}

export { StoreLocatorMap };
