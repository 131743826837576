import $ from "jquery";
import 'slick-carousel';
export function SlickSliders(){
    $(".slider.variable-slide .slider-stage").each(function(index, element) {
        $(element).not('.slick-initialized').slick({
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            centerPadding: '60px',
            centerMode: true,
            variableWidth: true,
            appendDots: $(this).closest('.slider').find('.slider-nav--dots'),
            appendArrows: $(this).closest('.slider').find('.slider-nav'),
            prevArrow: $(this).closest('.slider').find('.slider-nav--arrow.arrow-prev'),
            nextArrow: $(this).closest('.slider').find('.slider-nav--arrow.arrow-next')
        }); 
    });

    $(".slider.centered-slide .slider-stage").each(function(index, element) {
        $(element).not('.slick-initialized').slick({
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            //centerPadding: '60px',
            centerMode: true,
            variableWidth: true,
            appendDots: $(this).closest('.slider').find('.slider-nav--dots'),
            appendArrows: $(this).closest('.slider').find('.slider-nav'),
            prevArrow: $(this).closest('.slider').find('.slider-nav--arrow.arrow-prev'),
            nextArrow: $(this).closest('.slider').find('.slider-nav--arrow.arrow-next')
        }); 
    });
}
