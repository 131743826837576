export default class LoadHtmlpartials {
    constructor() {
        this.loadPartials();
    }

    loadPartials() {
        const self = this;

        document.querySelectorAll('html-partial').forEach(function (htmlPartialWrapper) {
            let htmlPartialSrc = htmlPartialWrapper.getAttribute('src'),
                thisCallback = htmlPartialWrapper.getAttribute('callback');

            fetch(htmlPartialSrc)
                .then(function (response) {
                    return response.text();
                })
                .then(function (htmlPartial) {
                    htmlPartialWrapper.innerHTML = htmlPartial;

                    if (thisCallback != undefined && thisCallback != "") {
                        self.handleCallback(thisCallback);
                    }
                })
                .catch(function (err) {
                    console.warn('Something went wrong.', err);
                });
        });
    }

    handleCallback(callbackName) {
        // Verifica che la funzione di callback esista nell'oggetto globale (window)
        if (typeof window[callbackName] === "function") {
            console.log(`Callback found: ${callbackName}`);
            window[callbackName](); // Chiama la funzione di callback
        } else {
            console.warn(`Callback not found: ${callbackName}`);
        }
    }
}
