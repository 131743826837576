import $ from "jquery";

// Define a class named FlyerTrack
export class FlyerTrack {
    // Debug var
    debug = false;

    // Constructor method to initialize the class
    constructor() {
        this.init();
    }

    // Initialization method
    init() {
        this.setupEventListeners();
        this.setupViewerOptions();
    }

    // Custom log method that checks the debug variable
    log(...args) {
        if (this.debug) {
            console.log(...args);
        }
    }

    // Method to set up event listeners
    setupEventListeners() {
        // Add click event listener to elements with class 'startFlyer'
        $('.startFlyer').on('click', (e) => this.handleStartFlyerClick(e));
    }

    // Handler for the 'startFlyer' click event
    handleStartFlyerClick(e) {
        // Prevent the default action of the event
        e.preventDefault();
        // Get the 'data-src' attribute value from the clicked element
        const source_pdf = $(e.currentTarget).attr('data-src');
        // Initialize the flipbook with the obtained source
        this.initializeFlipBook(source_pdf);
    }

    // Method to initialize the flipbook
    initializeFlipBook(source) {
        // Initialize the flipbook with the given source and set its height to 100%
        $("#flipbookPDFContainer").flipBook(source, {
            height: '100%'
        });
    }

    // Method to set up viewer options for the flipbook
    setupViewerOptions() {
        // Common callback function for multiple flipbook events
        const commonCallback = (flipbook, eventName) => {
            this.current_flipbook = flipbook;

            this.log(`flipbook ${eventName}`);
            this.log(this.current_flipbook);
        };

        // Set up default actions for various flipbook events
        DFLIP.defaults.onCreate = (flipbook) => {
            commonCallback(flipbook, 'create');
            this.setupCloseFlyerEvent();
        };

        DFLIP.defaults.onCreateUI = (flipbook) => {
            commonCallback(flipbook, 'create UI');
            this.pushFlyerData('flyer_open');
        };

        DFLIP.defaults.beforeFlip = (flipbook) => {
            commonCallback(flipbook, 'before-flip');
        }

        DFLIP.defaults.onFlip = (flipbook) => {
            commonCallback(flipbook, 'after-flip');
            if(this.current_flipbook.jumpStep == 2){
                let previousPageNumber = this.current_flipbook.currentPageNumber - 1;
                this.pushFlyerData('flyer_flip', previousPageNumber);
                this.pushFlyerData('flyer_flip');
            }else{
                this.pushFlyerData('flyer_flip');
            }
        }

        DFLIP.defaults.onReady = (flipbook) => {
            commonCallback(flipbook, 'ready');
        }
    }

    // Method to set up the close event for the flipbook
    setupCloseFlyerEvent() {
        // Add a one-time click event listener to the close button of the flipbook
        $('.df-lightbox-close.df-ui-btn.df-icon-close').one('click', (e) => this.handleCloseFlyerClick(e));
    }

    // Handler for the flipbook close event
    handleCloseFlyerClick(e) {
        this.log('flipbook close');
        this.log(this.current_flipbook);
        this.pushFlyerData('flyer_close');
    }

    // Method to push flyer data to the dataLayer
    pushFlyerData(flyerEvent, providedPageNumber=0 ) {
        // Destructure relevant properties from the current flipbook
        const { currentPageNumber, options, pageCount, jumpStep } = this.current_flipbook;
        const { dataElement, source } = options;
        const flyerData = dataElement[0].dataset;
        const pushPageNumber = (providedPageNumber>0) ? providedPageNumber :  currentPageNumber;


        // Push the data to the dataLayer
        dataLayer.push({
            'event': flyerEvent,
            'flyerCurrentPage': pushPageNumber,
            'flyerId': flyerData.flyerId,
            'flyerName': flyerData.flyerName,
            'flyerBrand': flyerData.flyerBrand,
            'flyerArea': flyerData.flyerArea,
            'flyerTotalPage': pageCount,
            'flyerViewType': jumpStep,
            'fliyerFile': source.split('/').pop(),
        });
    }
}
