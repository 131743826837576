import $ from "jquery";
import { Utilities } from "./utilities";

class headerNavMobile {

    mobileMenuMainDomElement = $('#mobileDropDownMenu');
    mobileMenuDomElement = $('#mobileDropDownMenu').find('.mobile-dropdown-menu--body');
    
    constructor() {
        this.mountMobileDropdownMenu();
        this.mainHeaderOnResize();
        this.mobileDropdownHeight();
    }

    mainHeaderOnResize() {
        $(window).on('resize', () => {
            this.mountMobileDropdownMenu();
        });
    }

    mountMobileDropdownMenu() {
        
        const menuMarkup = this.getDesktopDropDowMenuMarkup();
        //const mobileMenuDomElement = this.mobileMenuDomElement;
        
        if( !this.mobileMenuDomElement.hasClass('compiled') && Utilities.isMobile() ) {
            this.mobileMenuDomElement.html(menuMarkup);
            this.mobileMenuDomElement.addClass('compiled');
            this.cleanMobileMenuMarkup();
            this.toggleMobileMenu();
            this.toggleSubMenu();
        }

    }

    mobileDropdownHeight() {
        const tabMenuHeight = $('#mainHeader').find('.mobile-header').outerHeight();
        const viewportHeight = $(window).height();
        const mobileDropDownHeight = viewportHeight - tabMenuHeight;
        $('#mobileDropDownMenu').height(mobileDropDownHeight);
    }

    getDesktopDropDowMenuMarkup() {
        const menuDomElement = $('#navbarNavDropdown');
        const markup = menuDomElement.html();
        return markup;
    }

    cleanMobileMenuMarkup() {
        
        $(this.mobileMenuDomElement).find('.dropdown-toggle').each( (index,element) => {
            $(element).removeClass('dropdown-toggle');
            $(element).removeClass('nav-link');
            $(element).addClass('dropdown-toggle--mobile');
            $(element).removeAttr('data-bs-toggle');
        });

        $(this.mobileMenuDomElement).find('.nav-item').each( (index,element) => {
            $(element).removeClass('dropdown');
            $(element).removeClass('nav-item');
            $(element).addClass('nav-item--mobile');
        });

        $(this.mobileMenuDomElement).find('.dropdown-menu').each( (index,element) => {
            $(element).removeClass('dropdown-menu');
            $(element).addClass('dropdown-menu--mobile');
        });

        $(this.mobileMenuDomElement).find('.navbar-nav').removeClass('navbar-nav').addClass('navbar-nav--mobile');

    }

    toggleMobileMenu() {    
        $('#menuMobileToggle').on('click', (e) => {
            e.preventDefault();
            /* if ( !this.mobileMenuDomElement.hasClass('opened') ) {
                this.mobileMenuDomElement.addClass('opened');
            }else{
                this.mobileMenuDomElement.removeClass('opened');
            } */
            if ( !this.mobileMenuMainDomElement.hasClass('opened') ) {
                this.openMobileMenu();
            }else{
                this.closeMobileMenu();
            }



        });
    }

    openMobileMenu() {
        this.mobileMenuMainDomElement.addClass('opened');
        $('body').addClass('overflow-y-hidden');
        this.closeMobileMenuButton();
    }

    closeMobileMenu() {
        $('body').removeClass('overflow-y-hidden');
        this.mobileMenuMainDomElement.removeClass('opened');
        $(".dropdown-menu--mobile").hide();
        $(".dropdown-menu--mobile").removeClass('opened');;
        $(".dropdown-toggle--mobile").removeClass('opened');;
    }

    closeMobileMenuButton() {
        $('.close-mobile-menu').on('click', (e) => {
            e.preventDefault();
            //this.mobileMenuDomElement.removeClass('opened');
            this.closeMobileMenu();
        });
    }

    toggleSubMenu() {
        
        let submenuElement = ".dropdown-menu--mobile";

        $('.dropdown-toggle--mobile').on('click', (e) => {
            e.preventDefault();
            $(e.currentTarget).next(submenuElement).slideToggle();
            $(e.currentTarget).next(submenuElement).toggleClass('opened');
            $(e.currentTarget).toggleClass('opened');
        });

    }

}

export { headerNavMobile };
