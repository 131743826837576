import $ from "jquery";
import autoComplete from "@tarekraafat/autocomplete.js";
import { Utilities } from "./utilities";
import { StoreLocatorGetData } from "./store-locator--get-data";
import { StoreLocatorMap } from "./store-locator--map";

class StoreLocatorForm {

    search_parameters_obj = {
        area: [],
        type: [],
        dep: [],
        serv: [],
        store_id: ""
    };

    map = new StoreLocatorMap();
    storeLocatorData = new StoreLocatorGetData();

    constructor() {
        this.getTaxonomiesData();
        this.getStoresData();
        this.formStartSearch();
    }


    /**
     * Get the taxonomy data from store locator API
     */
    async getTaxonomiesData() {
        //const locator = new StoreLocatorGetData();
        try {
            const taxonomies_data = await this.storeLocatorData.getAllTermsFromAllTaxonomies();
            //console.log(taxonomies_data);
            this.mountTaxonomySelectOptions(taxonomies_data);
        } catch (error) {
            console.log('Error getting taxonomies from API: ' + error)
        }
        
    }

    /**
     * Get the stores data from store locator API
     */
    async getStoresData() {
        //const locator = new StoreLocatorGetData();
        try {

            const stores = await this.storeLocatorData.getStores();
            this.mountStoresSelect(stores);
            this.mountStoreSearchSuggestions(stores);

        } catch (error) {
            console.log('Error creating store suggestion: ' + error)
        }
    }

    /**
     * Mount the options for stores select
     * @param {*} storesObject 
     */
    mountStoresSelect(stores) {
        let select_option_template = '<option value="{{ value }}">{{ label }}</option>';
        let options_markup = "";
        $.each(stores, (index,element) => {
            //let this_option = select_option_template.replace('{{ value }}', index);
            let this_option = select_option_template.replace('{{ value }}', element.store_name);
            this_option = this_option.replace('{{ label }}',element.store_name);
            options_markup += this_option;
        });
        $('#store_name').append(options_markup);
    }

    /**
     * Mount stores suggestions in text filed
     * @param {*} storesObject 
     */
    mountStoreSearchSuggestions(stores) {
        let select_option_template = '<option value="{{ value }}">{{ label }}</option>';
        let options_markup = "";
        const stores_json_for_search = {}; 
        $.each(stores, (index,element) => {
            //console.log( this.storeLocatorData.getStoreURL(element) + " : " + JSON.stringify(element));
            
            let clean_store_name = element.store_name.replace(/["']/g, "");
            let this_option = select_option_template.replace('{{ value }}', clean_store_name);
            stores_json_for_search[clean_store_name] = this.storeLocatorData.getStoreURL(element);
            this_option = this_option.replace('{{ label }}',clean_store_name);
            options_markup += this_option;
        });
        //console.log(JSON.stringify(stores_json_for_search));
        $('#storesJsonSearch').attr('data-json',JSON.stringify(stores_json_for_search));
        $('#storesNameList').append(options_markup);
    }

    formStartSearch() {

        $('#storeLocatorForm').submit(function(e) {
            e.preventDefault();
        });

        $('.form-filter-control:not([data-parameter="store_id"])').on('change', (event) => {
            console.log('change select');
            this.addFilter(event.currentTarget);
        });

        $('.form-filter-control[data-parameter="store_id"]').on('change', (event) => {
            console.log('store id');
            let store_name = $(event.currentTarget).val();
            if( store_name !== "" ) {
                this.searchStoreByName(store_name);
            }
        });

        $('#cercaNegozio').on('change', (event) => {

            let input_value = $(event.currentTarget).val();
            this.searchStoreByName(input_value);

        });
        
    }

    searchStoreByName(store_name) {
        
        $('#noStoreFoundError').hide;
        
        let json_for_search = $('#storesJsonSearch').data('json');
            
        if( json_for_search[store_name] !== undefined ) {
            
            //console.log('store exists');
            window.open(json_for_search[store_name],"_self")
        
        }else{
            //console.log('no found')
            $('#noStoreFoundError').find('span').text(store_name);
            $('#noStoreFoundError').show();

        }

    }

    addFilter(element,type="common") {
        
        let filter_data = {
            id: "",
            parameter: "",
            value: "",
            label: ""
        }
        let filter_select_value = $(element).val();
        let filter_selected_option_text = $(element).find('option:selected').text();
        let filter_select_id = $(element).attr('id');
        let filter_form_parameter = $(element).data('parameter');
        let filter_form_parameter_label = $(element).data('label');

        if( filter_select_value !== "" ) {
                    
            filter_data.id = filter_select_id;
            filter_data.parameter = filter_form_parameter;
            filter_data.value = Number(filter_select_value);
            filter_data.label = filter_form_parameter_label + ": " + filter_selected_option_text;

            
            if( !Utilities.valueExistsInArray(filter_data.value, this.search_parameters_obj[filter_data.parameter]) ) {
                // Add the value to the search form parameters
                this.search_parameters_obj[filter_data.parameter].push(filter_data.value);
                // Show the label
                this.showFormFilterLabel(filter_data);
            }

        }

        // Reset select and remove :focus status
        // -----------------------------------------------------------------
        $(element).val("");
        $(element).blur();

        this.map.mountMapComponents(this.search_parameters_obj);

    }

    /**
     * Shows the filer label
     * @param {*} filter_data 
     */
    async showFormFilterLabel(filter_data) {
        let filter_label_template = '<button id="filter__{{ id }}__{{ value }}" data-value="{{ value }}" data-parameter="{{ parameter }}" class="btn btn-sm btn-outline-secondary filter-button">{{ label }} <i class="fa-light fa-xmark"></i></button>';
        let compiled_label_template = await Utilities.renderHandlebarsTemplate(filter_data, filter_label_template);
        let form_filter_labels_container = document.querySelector('#formFilterLabels');
        form_filter_labels_container.insertAdjacentHTML('beforeend',compiled_label_template);
        $('#formFilterLabelsWrapper').show();
        this.removeFilter();
    }

    /**
     * Remove the search filter label and reset the search
     */
    removeFilter() {
        $('.filter-button').on('click', (event) => {
            //console.log('clickonbuttonfilter');
            let form_parameter = $(event.currentTarget).data('parameter');
            let form_parameter_value = $(event.currentTarget).data('value');
            Utilities.removeItemFromArray(form_parameter_value,this.search_parameters_obj[form_parameter]);

            $(event.currentTarget).remove();
            this.map.mountMapComponents(this.search_parameters_obj);
            //console.log("Parameters updated: " + JSON.stringify(this.search_parameters_obj));
        });
    }

    /**
     * Reset Search Parameters
     * ---------------------------------------------------
     * @returns (object) the empty research parameters object
     */
    resetSearchParameters() {
        search_parameters_obj = {
            area: [],
            type: [],
            dep: [],
            serv: [],
            store_id: ""
        };
        return search_parameters_obj;
    }

    mountTaxonomySelectOptions(taxonomies_data) {

        let taxonomy_parameter = "";
        let select_option_template = '<option value="{{ value }}">{{ label }}</option>';

        const taxonomies = [
            {
                slug: 'area_coop_ci',
                name: 'Provincia',
            },
            {
                slug: 'type_coop_ci',
                name: 'Insegna',
            },
            {
                slug: 'serv_coop_ci',
                name: 'Servizi',
            },
            {
                slug: 'dep_coop_ci',
                name: 'Reparti',
            }
        ];

        $.each(taxonomies, (index,element)  => {
            
            let tax_terms = taxonomies_data[element.slug];
            let select_id = element.slug;
            let this_option = "";
            let options_markup = "";
            
            //console.log("tax_terms: " + JSON.stringify(tax_terms));

            $.each(tax_terms, (index, term) => {
                this_option = select_option_template.replace("{{ value }}",index);
                this_option = this_option.replace("{{ label }}",term);
                options_markup += this_option;
            });

            $('#' + select_id).append(options_markup);

        });


    }

    prepareTaxonomyArray(data) {

    }
}

export { StoreLocatorForm }