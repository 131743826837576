/**
 * News Slider Module
 */
import {tns} from "tiny-slider";
import { Utilities } from "./utilities";

function newsSlider() {
    if( Utilities.elementExits( '.news-row' ) ) {
        var news_slider = tns({
            container: '.news-row',
            //items: 1,
            loop: false,
            responsive: {
                0: {
                  edgePadding: 20,
                  gutter: 20,
                  items: 1.3
                },
                700: {
                  gutter: 30,
                  items: 2.3
                },
                900: {
                  items: 4,
                  edgePadding: 0,
                }
              },
            slideBy: 'page',
            autoplay: false,
            nav: false,
            controls: false
        });
    }
}

export {newsSlider}