/**
 * Big Slider Module
 * --------------------------------
 * Used form HomePage Main Slider
 */

import {tns} from "tiny-slider";
import { Utilities } from "./utilities";
import $ from 'jquery';

function bigSlider() {
    
    if( Utilities.elementExits('.big-slider--stage') ) {
        
        var big_slider = tns({
            container: '.big-slider--stage',
            items: 1,
            slideBy: 'page',
            autoplay: false,
            nav: false,
            controls: false,
            loop: false,
            gutter: 0,
        });
    
        $('.big-slider--thumbs--item button').click((e) => {
            var item = $(e.currentTarget).data('item');
            console.log('slider item: ' + item);
            big_slider.goTo(item);
        });
    
        $('.big-slider--controls--arrow.arrow-prev').click((e) => {
            big_slider.goTo("prev");
        });
    
        $('.big-slider--controls--arrow.arrow-next').click((e) => {
            big_slider.goTo("next");
        });

    }
}

export {bigSlider};