import {tns} from "tiny-slider";
import { Utilities } from "./utilities";

function iconBlurbsSlider() {
    if( Utilities.elementExits( '.icon-blurbs' ) ) {
        
        var icon_blurbs_slider = tns({
            container: '.icon-blurbs',
            //items: 1,
            loop: false,
            responsive: {
                0: {
                    edgePadding: 20,
                    gutter: 20,
                    items: 1.5
                },
                700: {
                    gutter: 30,
                    items: 3
                },
                900: {
                    items: 4,
                    edgePadding: 0,
                },
                1200: {
                    items: 6,
                    edgePadding: 0,
                }
            },
            slideBy: 'page',
            autoplay: false,
            nav: false,
            controls: false
        });

    }
}

export { iconBlurbsSlider } 