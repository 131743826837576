/**
 * Import SCSS main file
 * ----------------------------------------------------------------------
 */
import './scss/coop-ci.scss';
import '../node_modules/tiny-slider/dist/tiny-slider.css';
import './assets/js/dflip/css/dflip.css';
import '../node_modules/slick-carousel/slick/slick.css';
import '../node_modules/slick-carousel/slick/slick-theme.css';


window.dataLayer = window.dataLayer || [];

import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min';
import $ from 'jquery';
import './assets/js/jquery.cookie-consent';
$('body').cookieConsent({
    templatesDir: "https://coop-ci-website.stagingfattoria.it/templates/jquery-cookie-consent/",
    //templatesDir: "http://localhost/coop-ci-website/templates/jquery-cookie-consent/",
    debug: true
});

import './assets/js/dflip/js/dflip';
window.dFlipLocation = "https://coop-ci-website.stagingfattoria.it/dflip/";


/**
 * Import Main Modules
 * ----------------------------------------------------------------------
 */
import { Utilities } from "./assets/js/modules/utilities";
import { bigSlider } from "./assets/js/modules/big-slider";
import { iconBlurbsSlider } from "./assets/js/modules/icon-blurbs";
import { newsSlider } from "./assets/js/modules/news-slider";
import { storeServicesSlider } from "./assets/js/modules/store-services-slider";
import { SlickSliders } from "./assets/js/modules/slick-sliders";
import { StoreLocatorMap } from "./assets/js/modules/store-locator--map";
import { StoreLocatorForm } from "./assets/js/modules/store-locator--form";
import { panelCollapse } from "./assets/js/modules/panel-collapse";
import { headerNavDesktop } from "./assets/js/modules/header-nav--desktop";
import { headerNavMobile } from "./assets/js/modules/header-nav--mobile";
import { BodyResizes } from "./assets/js/modules/body-resizes";
import { Popups } from "./assets/js/modules/popups";
import { FlyerTrack } from "./assets/js/modules/flyer";

/**
 * Common Elements
 * ----------------------------------------------------------------------
 */

// Main Header Navigation
/* headerNavDesktop();
new headerNavMobile(); */

new BodyResizes();
new Popups();
new FlyerTrack();

SlickSliders();

/**
 * Home Page
 * ----------------------------------------------------------------------
 */

bigSlider();
newsSlider();
iconBlurbsSlider();
headerNavDesktop();

/**
 * Store Locator
 * ----------------------------------------------------------------------
 */
if( Utilities.isPage('#storeLocator') ) {
    const storeLocatorMapClass = new StoreLocatorMap;
    storeLocatorMapClass.mountMapComponents();
    const storeLocatoFormClass = new StoreLocatorForm;
    panelCollapse();
}

/**
 * Single Store Page
 * ----------------------------------------------------------------------
 */
storeServicesSlider();


/**
 * Load Html Parials
 * ------------------------------------------------------------------------
 * This code is just for dev and must be removed
 */
import LoadHtmlpartials from "./assets/js/modules/load-html-partials";
const loadHtmlpartialsInstance = new LoadHtmlpartials();
loadHtmlpartialsInstance.loadPartials();

function initHeaderDev() {
    headerNavDesktop();
    new headerNavMobile();
    new BodyResizes();
}

window.bigSlider = bigSlider;
window.iconBlurbsSlider = iconBlurbsSlider;
window.newsSlider = newsSlider;
window.initHeaderDev = initHeaderDev;
//window.dFlipLocation = "http://localhost/coop-ci-website/dflip/";
//window.bootstrap = bootstrap;


// -------- end Load Html Parials ------------

