class CookiesHandler {
	
    writeCookie(options) {
        if( options.name !== undefined && options.value !== undefined && options.name !== "" && options.value !== "") {
            
            let expires = ( options.expires !== undefined && options.expires != "" ) ? this.getDate(options.expires) : "";
            const path = options.path != undefined && options.path != "" ? options.path : "/";
            if( document.cookie = options.name + "=" + options.value + "; expires=" + expires + "; path=" + path ) {
                return true;
            }else{
                return false;
            }

        }else{
            console.error("cookieHandler.writeCookie() require a parameters object {name:'',value:'',expires:'',path:''} -- name and value are required!")
            return false;
        }
		
	}

	getCookie(cname) {
		let name = cname + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(";");
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == " ") {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

    deleteCookie(cname) {
        const yesterday = this.getDate("yesterday");
        document.cookie = cname + "= none; expires=" + yesterday + "; path=/";
    }

    getDate(days) {
        let date;
        const d = new Date();

        if( days == "yesterday") {
            date = d.setDate(d.getDate()-1);
            date = d.toUTCString();
        }else{
            d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
            date = d.toUTCString()
        }
        return date;
    }
}

export {CookiesHandler};