/**
 * Store Services Slider
 * ----------------------------------
 * Used to render at mobile sizes 
 * the service cards as carousel
 */

import {tns} from "tiny-slider";
import { Utilities } from "./utilities";

function storeServicesSlider() {
    
    if ( Utilities.isPhone() && Utilities.elementExits('.store-services-grid') ) {
        console.log('init services slider');
        var big_slider = tns({
            container: '.store-services-grid',
            items: 1.3,
            slideBy: 'page',
            autoplay: false,
            nav: true,
            navPosition: "bottom",
            controls: false,
            loop: false,
            gutter: 0,
        });
    }
    
}

export { storeServicesSlider };