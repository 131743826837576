import $ from "jquery";
import { Utilities } from "./utilities";

class StoreLocatorGetData {
    api_domain = (process.env.API_DOMAIN) ? process.env.API_DOMAIN : API_DOMAIN;
    api_get_token_endpoint = (process.env.API_TOKEN_ENDPOINT) ? process.env.API_TOKEN_ENDPOINT : API_TOKEN_ENDPOINT;
    api_stores_endpoint = (process.env.API_STORES_ENDPOINT) ? process.env.API_STORES_ENDPOINT : API_STORES_ENDPOINT;
    api_taxonomies_endpoint = (process.env.API_TAXONOMIES_ENDPOINT) ? process.env.API_TAXONOMIES_ENDPOINT : API_TAXONOMIES_ENDPOINT;
    client_id = (process.env.API_CLIENT_ID) ? process.env.API_CLIENT_ID : API_CLIENT_ID;
    client_secret = (process.env.API_CLIENT_SECRET) ? process.env.API_CLIENT_SECRET : API_CLIENT_SECRET;
    api_token = null;
    stores_data = null;

    getAPIToken() {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: this.api_get_token_endpoint,
                method: "POST",
                timeout: 0,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                data: {
                    grant_type: "client_credentials",
                    client_id: this.client_id,
                    client_secret: this.client_secret,
                },
                success: (data) => {
                    this.api_token = data.access_token;
                    resolve();
                },
                error: () => {
                    console.log("AJAX Error getting token");
                    reject();
                },
            });
        });
    }

    async getAllTermsFromAllTaxonomies() {
        try {
            await this.getAPIToken();
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: this.api_domain + this.api_taxonomies_endpoint,
                    method: "POST",
                    timeout: 0,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: {
                        access_token: this.api_token
                    },
                    success: (data) => {
                        const taxonomies = JSON.parse(data);
                        resolve(taxonomies);
                    },
                    error: () => {
                        console.log("AJAX Error getting stores");
                        reject();
                    },
                });
            });
        } catch (error) {
            console.log("Error getting terms from API:", error);
        }
    }

    async getTermsFromTaxonomy(taxonomy_name) {
        try {
            await this.getAPIToken();
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: this.api_domain + this.api_taxonomies_endpoint,
                    method: "POST",
                    timeout: 0,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: {
                        access_token: this.api_token,
                        taxonomy_name: taxonomy_name
                    },
                    success: (data) => {
                        const terms = JSON.parse(data);
                        resolve(terms);
                    },
                    error: () => {
                        console.log("AJAX Error getting stores");
                        reject();
                    },
                });
            });
        } catch (error) {
            console.log("Error getting terms from API:", error);
        }
    }

    async getStores(search_parameters={}) {

        try {

            await this.getAPIToken();

            return new Promise((resolve, reject) => {

                if( Utilities.isObjectEmpty(search_parameters) ) {
                    search_parameters = {
                        access_token: this.api_token,
                        store_id: ""
                    }
                }else{
                    search_parameters.access_token = this.api_token;
                }

                $.ajax({
                    url: this.api_stores_endpoint,
                    method: "POST",
                    timeout: 0,
                    data:  search_parameters,
                    success: (data) => {
                        const stores = JSON.parse(data);
                        resolve(stores);
                    },
                    error: () => {
                        console.log("AJAX Error getting stores");
                        reject();
                    },
                });
            });
        } catch (error) {
            console.log("Error getting stores from API:", error);
        }
    }

    getStoreURL( store_object ) {

        const store_type_object = this.getStoreType(store_object);

        const store_name = store_object.store_slug;
        const store_type = store_type_object.slug;
        const store_address = ( store_object.postmeta.sl_store__geolocation_address === undefined ) ? "" : Utilities.slugifyString(store_object.postmeta.sl_store__geolocation_address);
        const store_street_number = ( store_object.postmeta.sl_store__geolocation_street_number === undefined ) ? "" : Utilities.slugifyString(store_object.postmeta.sl_store__geolocation_street_number);
        const store_city = ( store_object.postmeta.sl_store__geolocation_city === undefined ) ? "" : Utilities.slugifyString(store_object.postmeta.sl_store__geolocation_city);
        const store_state = ( store_object.postmeta.sl_store__geolocation_state === undefined ) ? "" : Utilities.slugifyString(store_object.postmeta.sl_store__geolocation_state);

        const site_url = (process.env.SITE_URL) ? process.env.SITE_URL : SITE_URL;
        const stores_url_slug = (process.env.SITE_STORES_URL_SLUG) ? process.env.SITE_STORES_URL_SLUG : SITE_STORES_URL_SLUG;

        let store_url = site_url + stores_url_slug + "/" + store_type + "-" + store_name;


        if ( store_address !== "" )  store_url += "-" + store_address;
        if ( store_street_number !== "" ) store_url += "-" + store_street_number;
        if ( store_city !== "" ) store_url += "-" + store_city;
        if ( store_state !== "" ) store_url += "-" + store_state;

        store_url = ( store_object.postmeta.sl_store__frontend_url === undefined ) ? store_name : store_object.postmeta.sl_store__frontend_url;
        store_url = stores_url_slug + '/' + store_url;
        return store_url;

    }

    getStoreType( store_object ) {

        let store_type = {};

        $.each(store_object.type_coop_ci, (index,element) => {
            store_type = element;
            return false;
        });

        return store_type;
    }

}

export {StoreLocatorGetData}
